@charset "UTF-8";

// Color
@import "components/color-variables";
@import "components/color-classes";

// Variables;
@import "components/variables";

// Reset
@import "components/normalize";

// components
// @import "components/global";
// @import "components/badges";
// @import "components/icons-material-design";
// @import "components/grid";
// @import "components/navbar";
// @import "components/typography";
// @import "components/transitions";
// @import "components/cards";
// @import "components/toast";
// @import "components/tabs";
// @import "components/tooltip";
// @import "components/buttons";
// @import "components/dropdown";
// @import "components/waves";
// @import "components/modal";
// @import "components/collapsible";
// @import "components/chips";
// @import "components/materialbox";
// @import "components/forms/forms";
// @import "components/table_of_contents";
// @import "components/sidenav";
// @import "components/preloader";
// @import "components/slider";
@import "components/carousel";
//@import "components/tapTarget";
//@import "components/pulse";
//@import "components/datepicker";
//@import "components/timepicker";

// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
@import '../vendors/materialize/sass/materialize';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-header';
//@import 'modules/module-soumission';

p, footer .footer-wrapper .copyright, footer .footer-wrapper .mediaweb, footer .footer-wrapper .mediaweb a span {
    font-family: $font-family-2;
}
footer .footer-wrapper .copyright, footer .footer-wrapper .mediaweb, footer .footer-wrapper .mediaweb a span {
    font-size: 14px;
}

/***** MENU *****/

.px4, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title {
    padding: 0;
}
.mr2, nav.module-menu .menu-navbar .wrapper.right .main-menu-top > ul li.phone a .icon, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li.phone a .icon {
    margin-left: 0.5rem;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu-top > ul li.phone:hover .phonenumber {
    color: #fff;
}
nav.module-menu.scrolled .menu-navbar.shadow.nav-visible {
    background: #2f2f2f;
}





/*******************************************************************************
* POP-UP
*******************************************************************************/

.md-content.popup-box {

   h3 {
    font-family: $font-family-1;
    color: #fa6e1c;
    font-size: $font-size-40;
    letter-spacing: 1.5px;
    padding-bottom: 10px;
    text-transform: initial;
    }

    p {
        font-weight: 500;
        font-size: $font-size-24;
        color: #272727;
    }
}

/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#blocPrincipal {
    background: #272727;

    .backgroundHexa {
       img {
        width: 100%;
       }
    }

    .blocIntro {
        text-align: center;
        padding: 50px 0 75px 0;

        p {
            font-weight: 500;
            color: #fff;
            font-size: $font-size-30;
            line-height: 1.5;
        }
    }
}

.blocBackground {
    background: url("../images/accueil_section04_bg.jpg") no-repeat;
    padding: 88px 2% 50px 10%;
    h4 {
        color: #fff;
        font-size: $font-size-46;
        letter-spacing: 1.5px;
    }

    h3 {
        color: #fff;
        font-size: $font-size-60;
        letter-spacing: 1.5px;
    }

    .texte {
        margin-top: 300px;

        p {
            font-weight: 500;
            color: #fff;
            font-size: $font-size-30;
            letter-spacing: 1.5px;
        }
    }
}

#section-2.first {
    margin-top: -75px;
}

#section-2 {
    background: #272727;

    h3.titreVedette {
        padding: 0;
        transform: translateY(150%);
        text-align: center;
        color: #fff;
        font-size: $font-size-40;
        letter-spacing: 1.5px;
        text-shadow: 0px 0px 15px #fa6e1c, 0px 0px 15px #fa6e1c;
    }

    .blocEnVedette {

      position: relative;
      min-height: 400px;
      @media screen and (max-width: $size-xs-max){
        height: 650px;
        .bloc {
          width: 50%!important;
        }
      }

      #carousel-prev, #carousel-next {
            top: 50%;
            cursor: pointer;
            position: absolute;
            transform: translateY(-50%);
            z-index: 3;
            font-size: 35px;
            transition: .3s;
            i {
                  color: $color-primary;
                  transition: .3s;
            }
      }

      #carousel-prev {
            left: 10%;
            i:hover {
                  transition: .3s;
                  transform: translateX(-50%);
            }
      }
      #carousel-next {
            right: 10%;
            i:hover {
                  transition: .3s;
                  transform: translateX(50%);
            }
      }

        .bloc {
            max-width: 400px;
            width: 30%;

            .info {
                background: #fff;

                .images {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    & > * {
                      padding: 2px;
                      width: 25%;
                    }
                }
                .texte {
                    padding: 20px 15px;

                    h3 {
                        color: #fa6e1c;
                        font-size: $font-size-30;
                        letter-spacing: 1.5px;
                        padding-bottom: 10px;
                    }
                    p {
                        font-weight: 200;
                        color: #000;
                        font-size: $font-size-18;
                        margin: 0;
                        line-height: 1.3;
                        span {
                            font-weight: 500;
                        }
                    }
                    .prix {
                        background: url(../images/accueil_section05_icon_prix.png) no-repeat center;
                        padding: 10px 0;
                        position: relative;
                        top: 50px;

                        h4 {
                            color: #fff;
                            font-size: $font-size-40;
                            letter-spacing: 1.5px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .blocListe {
        padding: 100px 0;
        display: flex;
        justify-content: space-around;
        background: url("../images/accueil_section06_bg.jpg") no-repeat;
        background-position: center;

        .liste {
            li {
                color: #fff;
                font-size: $font-size-20;
                line-height: 2;
                letter-spacing: 1.5px;
            }
        }
    }
}


/*******************************************************************************
* INVENTAIRE DE VÉHICULES
*******************************************************************************/

#blocIntro_inventaire {
    text-align: center;
    padding: 0 0 50px 0;
    background: #272727;

    p {
        font-weight: 500;
        color: #fff;
        font-size: $font-size-30;
        line-height: 1.5;
    }
}

#blocInventaire {
    background: #272727;

    .bloc {
        display: flex;
        background: #fa6e1c;
        padding: 25px 2%;
        margin-bottom: 100px;
        &:last-child {
            margin-bottom: 0;
        }

        .left-side {
            width: 25%;

            .galerie {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;
                .thumb {
                    padding: 3px;
                    width: 25%;
                }
            }
        }

        .right-side {
            width: 75%;
            padding-left: 35px;

            h3 {
                color: #fff;
                font-size: $font-size-70;
            }
            h4 {
                color: #fff;
                font-size: $font-size-36;
            }

            .blocInfo {
                background: #fff;
                padding: 15px 25px;
            }

            .information {
                display: flex;
                justify-content: space-between;
                p {
                    font-weight: 500;
                    color: #000;
                    font-size: $font-size-24;
                    margin-bottom: 0;
                }
            }

            .garantie {
                p {
                    font-weight: 300;
                    color: #000;
                    font-size: $font-size-24;
                    margin-bottom: 0;
                }
            }

            .description {
                display: flex;
                justify-content: space-around;
                padding-top: 20px;

                .liste {
                    ul {
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 50px;

                        li {
                            font-weight: 500;
                            color: #000;
                            font-size: $font-size-20;
                            text-transform: initial;
                            font-family: $font-family-2;
                            margin-bottom: -20px;
                            width: 33%;
                        }
                    }
                }
                .prix {
                    background: url(../images/inventaire_section03_icon_price.jpg) no-repeat right;
                    padding: 15px 0;
                    display: flex;
                    justify-content: center;
                    background-size: 100%;
                }
                p.tel {
                    font-weight: normal;
                    color: #fa6e1c;
                    font-size: $font-size-24;
                    margin-bottom: -10px;
                    text-align: right;
                }
                .telephone {
                    margin-bottom: -10px;
                }
                .telephone, .courriel {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    p {
                        padding-right: 10px;
                        font-weight: normal;
                        color: #000;
                        font-size: $font-size-24;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICES MÉCANIQUE
*******************************************************************************/

#blocIntro_orangeServices {
    padding: 50px 10%;
    background: #fa6e1c;
    display: flex;
    justify-content: space-between;

    h3 {
        color: #fff;
        font-size: $font-size-70;
    }
    p {
        font-weight: 500;
        color: #fff;
        font-size: $font-size-30;
        line-height: 1.5;
    }
}

#blocIntro {
    text-align: center;
    padding: 50px 5%;
    background: #272727;

    p {
        font-weight: 500;
        color: #fff;
        font-size: $font-size-30;
        line-height: 1.5;
    }
}

#blocBackground_services {
    background: url("../images/servicesmecanique_section04_bg.jpg") no-repeat;
    background-size: cover;
    padding: 150px 2%;
    display: flex;
    justify-content: space-evenly;

    h3 {
        color: #fff;
        font-size: $font-size-70;
        letter-spacing: 1.5px;
    }

    li {
        font-weight: 500;
        color: #fff;
        font-size: $font-size-40;
        line-height: 1.5;
        text-transform: initial;
        font-family: $font-family-2;
    }
}


/*******************************************************************************
* FINANCEMENT
*******************************************************************************/

.bursts {
    background: #272727;
}

#blocIntro_orange {
    padding: 50px 10%;
    background: #fa6e1c;

    p {
        font-weight: 500;
        color: #fff;
        font-size: $font-size-30;
        line-height: 1.5;
    }
}

#blocBackground {
    background: url("../images/financement_section04_bg.jpg") no-repeat;
    background-size: cover;
    padding: 255px 2%;

    h3.first {
        padding-bottom: 65px;
    }
    h3 {
        color: #fff;
        font-size: $font-size-40;
        letter-spacing: 1.5px;
        text-align: center;
    }
}


/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#titrePage {
    background: #272727;
    text-align: center;
    padding: 50px 0;

    h2 {
        font-size: $font-size-40;
        color: #fa6e1c;
        font-style: italic;
        text-shadow: 3px 3px 5px #000,
                     -3px -3px 5px #000,
                     3px -3px 5px #000,
                     -3px 3px 5px #000;
    }
}

#blocCoordonnées {
    padding: 100px 0;
    display: flex;
    justify-content: space-around;
    background: #272727;

    .info, .tel, .courriel, .heures {
        display: flex;
        align-items: baseline;
        padding-bottom: 30px;

        .icon {
            padding-right: 15px;
        }
    }

    h3 {
        font-size: $font-size-48;
        color: #fa6e1c;
        padding-bottom: 35px;
        margin-left: 35px;
    }
    p {
        font-weight: normal;
        font-size: $font-size-28;
        line-height: 1.3;
        color: #fff;
    }

    .right-side {
        p {
            display: flex;
            justify-content: space-between;
            span {
                padding-left: 125px;
            }
        }
    }
}

#content h3#firstHeading {
    font-size: $font-size-24;
    font-weight: bold;
    color: #f15a23;
    text-transform: initial;
}
#bodyContent a {
    font-weight: normal;
    font-size: $font-size-20;
    line-height: 1.3;
    color: #000;
    &:hover {
        font-weight: bold;
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1700px) {

#blocIntro_orange p br {
    display: none;
}
}

@media screen and (max-width: 1690px) {

.blocBackground {
    padding: 88px 2% 50px 3%;
}
}

@media screen and (max-width: 1550px) {

.blocBackground h4 {
    font-size: 1.9rem;
}
}

@media screen and (max-width: 1490px) {

#blocIntro_orangeServices p br {
    display: none;
}
#blocIntro_orangeServices .right-side {
    width: 55%;
}
#blocBackground_services {
    padding: 100px 2%;
}
#blocBackground_services .right-side {
    width: 45%;
}
}

@media screen and (max-width: 1460px) {
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title div {
    padding: 0 30px;
}
.bursts .bursts-container .burst-center {
    margin: 0px 50px;
}
.blocBackground {
    padding: 88px 2% 50px 7%;
}
}

@media screen and (max-width: 1440px) {

#blocInventaire .bloc .right-side .description .liste ul li {
    margin-bottom: 20px;
    width: 35%;
    line-height: 1;
}
}

@media screen and (max-width: 1402px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title div {
    padding: 0 20px;
}
.blocBackground {
    padding: 88px 2% 15px 10%;
}
.blocBackground h4 {
    font-size: 1.6rem;
}
}

@media screen and (max-width: 1370px) {

#blocIntro p br {
    display: none;
}
}

@media screen and (max-width: 1332px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title div {
    padding: 0 15px;
}
}

@media screen and (max-width: 1310px) {

#blocInventaire .bloc .right-side .description .liste ul li {
    margin-bottom: 15px;
    width: 40%;
}
}

@media screen and (max-width: $size-md-max) {

.bursts {
    padding: 50px 2%;
}
.bursts .bursts-container .burst .text-container .border p {
    font-size: 1.7rem;
}
.blocBackground {
    padding: 88px 2% 15px 5%;
}
}

@media screen and (max-width: 1062px) {

nav.module-menu .menu-navbar .wrapper.right {
    padding-right: 5px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title div {
    padding: 0 8px;
}
.blocBackground h4 {
    font-size: 1.5rem;
}
}

@media screen and (max-width: 1024px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 1.3rem;
}
.bursts .bursts-container .burst .text-container .border p {
    font-size: 1.3rem;
}
.blocBackground h4 {
    font-size: 1.4rem;
}
#section-2 .blocListe {
    background: none;
    padding: 75px 0;
}
.blocBackground {
    padding: 88px 5% 15px 5%;
}
}

@media screen and (max-width: $size-sm-max) {

.bursts .bursts-container .burst-center {
    margin: 0px;
}
#blocPrincipal .blocIntro {
    padding: 50px 2% 75px 2%;
}
#blocPrincipal .blocIntro p br {
    display: none;
}
.blocBackground {
    padding: 55px 5%;
}
.blocBackground .texte p {
    line-height: 1.3;
}
.blocBackground h4 {
    font-size: 1.8rem;
}
#section-2 .blocListe .liste li {
    line-height: 1.5;
    padding-bottom: 15px;
}
#section-2.first {
    margin-top: -40px;
}
#blocInventaire .bloc {
    flex-direction: column;
}
#blocInventaire .bloc .left-side {
    width: 80%;
    margin: auto;
}
#blocInventaire .bloc .right-side {
    width: 100%;
    padding-left: 0;
    padding-top: 35px;
}
#blocInventaire .bloc .right-side .description .liste ul li {
    width: 50%;
}
#blocBackground h3.first br {
    display: none;
}
#blocBackground {
    padding: 175px 2%;
}
}


@media screen and (max-width: $size-xs-max) {

.blocBackground {
    padding: 30px 5%;
}
#section-2 .blocListe {
    padding: 55px 0;
}
#section-2 .blocListe .liste li {
    font-size: 1.2rem;
}
.blocBackground h4 {
    font-size: 1.5rem;
}
.blocBackground h3 {
    font-size: 2.8rem;
}
.bursts .bursts-container .burst .text-container .border p {
    font-size: 1.9rem;
}
#modal-1.md-modal .popup-box {
    border: none;
}
#section-2.first {
    margin-top: 0px;
}
#blocInventaire .bloc .right-side .information, #blocInventaire .bloc .right-side .description {
    flex-direction: column;
}
#blocInventaire .bloc .left-side {
    width: 90%;
}
#blocIntro_orangeServices {
    padding: 50px 5%;
    flex-direction: column;
}
#blocIntro_orangeServices .right-side {
    width: 100%;
}
#blocIntro_orangeServices h3, #blocBackground_services h3 {
    padding-bottom: 20px;
}
#blocBackground_services {
    padding: 100px 5%;
    flex-direction: column;
}
#blocBackground_services .right-side {
    width: 100%;
}
#blocBackground_services h3 br {
    display: none;
}
#blocBackground {
    padding: 75px 2%;
}
#blocCoordonnées {
    padding: 50px 5% 100px 5%;
    flex-direction: column;
}
#blocCoordonnées .right-side {
    padding-top: 25px;
}
}
